import React from "react";
import { Link } from "gatsby";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import LogoIcon from "../../assests/svg/Logo_2.1_noText.svg";
import { Grid } from "@material-ui/core";
import { Button } from "@material-ui/core";
import MobileFooter from "./MobileFooter";
import Typography from "@material-ui/core/Typography";

import FacebookIcon from "@material-ui/icons/Facebook";
import TwitterIcon from "@material-ui/icons/Twitter";
import LinkedInIcon from "@material-ui/icons/LinkedIn";

import { useStyles } from "./Footer.styling";

const Footer = () => {
  const classes = useStyles();
  const desktop = useMediaQuery("(min-width:769px)");

  const links = [
    {
      name: "About Us",
      linksUrl: "/about",
    },
    {
      name: "Careers",
      linksUrl: "/careers",
    },
    {
      name: "Request Callback",
      linksUrl: "/callback",
    },
    {
      name: "Services",
      linksUrl: "/services",
    },
    {
      name: "Contact",
      linksUrl: "/contact",
    },
  ];

  const socialLinks = [
    {
      name: "Facebook",
      linksUrl: "https://www.facebook.com/PCH-Services-101086602195819",
    },
    {
      name: "Twitter",
      linksUrl: "https://twitter.com/PCHServices2",
    },
    {
      name: "LinkedIn",
      linksUrl: "https://www.linkedin.com/company/pch-services/",
    },
  ];

  const Icons = {
    Twitter: TwitterIcon,
    LinkedIn: LinkedInIcon,
    Facebook: FacebookIcon,
  };

  return (
    <footer
      className={classes.footer}
      style={{ padding: desktop ? "0 5%" : "0" }}
    >
      {desktop && (
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
          // xs={12}
          wrap="nowrap"
        >
          <Grid item container sm={6} direction="column">
            <LogoIcon className={classes.logoIcon} />
            <Typography variant="h5" className={classes.title}>
              PCH Services
            </Typography>
          </Grid>
          <Grid
            wrap="nowrap"
            item
            container
            direction="row"
            justify="space-between"
            sm={6}
          >
            <Grid
              item
              container
              direction="column"
              justify="flex-start"
              alignItems="flex-start"
              sm={3}
            >
              <ul>
                {links.map(
                  (link, index) =>
                    index < 2 && (
                      <Grid key={link.name} item>
                        <li className={classes.listItem}>
                          <Button classes={{ root: classes.linkButtonLabel }}>
                            <Link className={classes.link} to={link.linksUrl}>
                              {link.name}
                            </Link>
                          </Button>
                        </li>
                      </Grid>
                    )
                )}
              </ul>
            </Grid>
            <Grid
              item
              container
              direction="column"
              justify="flex-start"
              alignItems="flex-start"
              sm={3}
            >
              <ul>
                {links.map(
                  (link, index) =>
                    index > 1 && (
                      <Grid key={link.name} item>
                        <li className={classes.listItem}>
                          <Button classes={{ root: classes.linkButtonLabel }}>
                            <Link className={classes.link} to={link.linksUrl}>
                              {link.name}
                            </Link>
                          </Button>
                        </li>
                      </Grid>
                    )
                )}
              </ul>
            </Grid>
            <Grid
              item
              container
              direction="column"
              justify="flex-start"
              alignItems="flex-start"
              sm={3}
            >
              <ul>
                {socialLinks.map((link) => (
                  <Grid key={link.name} item>
                    <Button classes={{ root: classes.linkButtonLabel }}>
                      <li className={classes.socialLabel}>
                        {React.createElement(Icons[link.name])}
                        <Link className={classes.link} to={link.linksUrl}>
                          {link.name}
                        </Link>
                      </li>
                    </Button>
                  </Grid>
                ))}
              </ul>
            </Grid>
            <Grid
              item
              container
              direction="column"
              justify="flex-start"
              alignItems="flex-start"
              sm={3}
            >
              <ul>
                <Grid item>
                  <Typography style={{ fontWeight: 500 }}>Email:</Typography>
                  <li className={classes.listItem}>
                    <Button classes={{ root: classes.linkButtonLabel }}>
                      <a
                        className={classes.link}
                        href="mailto:info@pchservices.uk"
                        style={{ textTransform: "lowercase" }}
                      >
                        info@pchservices.uk
                      </a>
                    </Button>
                  </li>
                </Grid>
                <Grid item>
                  <Typography style={{ fontWeight: 500 }}>Tel:</Typography>
                  <li className={classes.listItem}>
                    <Button classes={{ root: classes.linkButtonLabel }}>
                      <span
                        className={classes.link}
                        style={{ textTransform: "lowercase" }}
                      >
                        01564335999
                      </span>
                    </Button>
                  </li>
                </Grid>
                <Grid item>
                  <Typography style={{ fontWeight: 500 }}>WhatsApp:</Typography>
                  <li className={classes.listItem}>
                    <Button classes={{ root: classes.linkButtonLabel }}>
                      <span
                        className={classes.link}
                        style={{ textTransform: "lowercase" }}
                      >
                        07831522215
                      </span>
                    </Button>
                  </li>
                </Grid>
              </ul>
            </Grid>
          </Grid>
        </Grid>
      )}
      {!desktop && <MobileFooter />}
      <p className={classes.copyright}>
        &copy; PCH Services {new Date().getFullYear()}{" "}
      </p>
    </footer>
  );
};

export default Footer;
