import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    fontFamily: "Montserrat_Subrayada",
    color: "white",
  },
  navbar: {
    height: 100,
    marginLeft: "auto",
    marginRight: "auto",
    width: "100%",
    left: "0%",
    right: "0%",
    justifyContent: "center",
    [theme.breakpoints.up('tablet')]: {
      width: "90%",
      left: "5%",
      right: "5%",
    },
  },
  logoIcon: {
    width: "6rem",
    height: "6rem",
  },
  menuIcon: {
    width: "2rem",
    height: "2rem",
    color: "white",
  },
  link: {
    color: "white",
    textDecoration: "none",
    textTransform: "Capitalize",
    fontSize: "18px",
    display: "inline-block",
    "&::after": {
      content: '""',
      display: "block",
      width: 0,
      height: "2px",
      backgroundColor: "white",
      transition: "width .3s",
    },
    "&:active::after": {
      width: "100%",
    },
    "&:hover::after": {
      width: "100%",
    },
    
  },
  mobileNav: {
    textAlign: "end",
  },
  linkButtonLabel: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  drawerPaper: {
    left: 0,
    backgroundColor: theme.palette.primary.main,
  },
  menuCloseIcon: {
    color: "black",
  },
  mobileLinkList:{
    display:"flex",
    flexDirection:"column"
  }
}));
