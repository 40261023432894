import React from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider, responsiveFontSizes } from "@material-ui/core/styles";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { Link } from "gatsby";

import { Navbar } from "../Navbar";
import { Footer } from "../Footer";
import { CookieChecker} from '../CookieChecker'

import { theme } from "../../theme";
import "../../global.css";
import { Typography } from "@material-ui/core";
import { useStyles } from "./Layout.styling";

const Layout = ({ gutter, marginTop, breadcrumb = [], children,navbarPosition }) => {
  const classes = useStyles();
  const responsiveTheme = responsiveFontSizes(theme);
  const desktop = useMediaQuery("(min-width:769px)");
  return (
    <ThemeProvider theme={responsiveTheme}>
      <CssBaseline />
      <CookieChecker/>
      <Navbar position={navbarPosition}/>
      <main
        style={{
          marginTop: marginTop ? 100 : 0,
          padding: desktop && gutter ? "0 5%" : 0,
        }}
        className={classes.main}
      >
        {breadcrumb.length > 0 && (
          <Breadcrumbs
            aria-label="breadcrumb"
            style={{ marginLeft:!gutter ? "5%" : "auto"}}
            classes={{ root: classes.breadcrumb }}
          >
            {breadcrumb.map((item, index) => {
              if (index < breadcrumb.length - 1) {
                return <Link key={item.link} to={item.link}>{item.name}</Link>;
              }
            })}
            <Typography color="textPrimary">
              {breadcrumb[breadcrumb.length - 1]?.name}
            </Typography>
          </Breadcrumbs>
        )}
        {children}
      </main>
      <Footer />
    </ThemeProvider>
  );
};

export default Layout;
