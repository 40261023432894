import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  footer: {
    position: "absolute",
    // bottom: 0,
    width: "100%",
    height: 0,
  },
  logoIcon: {
    width: "8rem",
    marginLeft: "1rem",
  },
  linkButtonLabel: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  link: {
    color: "black",
    textDecoration: "none",
    textTransform: "Capitalize",
    whiteSpace: "nowrap",
    display: "inline-block",
    "&::after": {
      content: '""',
      display: "block",
      width: 0,
      height: "2px",
      backgroundColor: "black",
      transition: "width .3s",
    },
    "&:hover::after": {
      width: "100%",
    },
  },
  socialLabel: {
    display: "flex",
    marginBottom: "0.5rem",
  },
  copyright: {
    textAlign: "center",
  },
  title: {
    flexGrow: 1,
    fontFamily: "Montserrat_Subrayada",
    color: "#767474",
    marginLeft: "1rem",
  },
}));
