import React from "react";
import { Link } from "gatsby";

import { Grid } from "@material-ui/core";
import { Button } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";

import FacebookIcon from "@material-ui/icons/Facebook";
import TwitterIcon from "@material-ui/icons/Twitter";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import { useStyles } from "./MobileFooter.styling";

const MobileFooter = () => {
  const classes = useStyles();

  const links = [
    {
      name: "About Us",
      linksUrl: "/about",
    },
    {
      name: "Careers",
      linksUrl: "/careers",
    },
    {
      name: "Request Callback",
      linksUrl: "/callback",
    },
    {
      name: "Services",
      linksUrl: "/services",
    },
    {
      name: "Contact",
      linksUrl: "/contact",
    },
  ];

  const socialLinks = [
    {
      name: "Facebook",
      linksUrl: "https://www.facebook.com/PCH-Services-101086602195819",
    },
    {
      name: "Twitter",
      linksUrl: "https://twitter.com/PCHServices2",
    },
    {
      name: "LinkedIn",
      linksUrl: "https://www.linkedin.com/company/pch-services/",
    },
  ];

  const Icons = {
    Twitter: TwitterIcon,
    LinkedIn: LinkedInIcon,
    Facebook: FacebookIcon,
  };

  return (
    <>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon classes={{ root: classes.expandIcon }} />}
          aria-controls="navigation-links"
          id="navigation-links"
          classes={{
            root: classes.accordionSummary,
          }}
        >
          <Typography className={classes.heading}>Page Links</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ul className={classes.listGroup}>
            {links.map((link) => (
              <Grid key={link.name} item>
                <li className={classes.listItem}>
                  <Button classes={{ root: classes.linkButtonLabel }}>
                    <Link className={classes.link} to={link.linksUrl}>
                      {link.name}
                    </Link>
                  </Button>
                </li>
              </Grid>
            ))}
          </ul>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon classes={{ root: classes.expandIcon }} />}
          aria-controls="social-links"
          id="social-links"
          classes={{
            root: classes.accordionSummary,
          }}
        >
          <Typography className={classes.heading}>Social</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ul className={classes.listGroup}>
            {socialLinks.map((link) => (
              <Grid key={link.name} item>
                <Button classes={{ root: classes.linkButtonLabel }}>
                  <li className={classes.socialLabel}>
                    {React.createElement(Icons[link.name])}
                    <Link className={classes.link} to={link.linksUrl}>
                      {link.name}
                    </Link>
                  </li>
                </Button>
              </Grid>
            ))}
          </ul>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon classes={{ root: classes.expandIcon }} />}
          aria-controls="contact-links"
          id="contact-links"
          classes={{
            root: classes.accordionSummary,
          }}
        >
          <Typography className={classes.heading}>Contact</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ul className={classes.listGroup}>
            <Grid item style={{ display: "flex", alignItems: "center" }}>
              <Typography style={{fontWeight:500}}>Email:</Typography>
              <li className={classes.listItem}>
                <Button classes={{ root: classes.linkButtonLabel }}>
                  <a
                    className={classes.link}
                    href="mailto:info@pchservices.uk"
                    style={{ textTransform: "lowercase" }}
                  >
                    info@pchservices.uk
                  </a>
                </Button>
              </li>
            </Grid>
            <Grid item style={{ display: "flex", alignItems: "center" }}>
              <Typography style={{fontWeight:500}}>Tel:</Typography>
              <li className={classes.listItem}>
                <Button classes={{ root: classes.linkButtonLabel }}>
                  <a
                    className={classes.link}
                    href="tel:01564335999"
                    style={{ textTransform: "lowercase" }}
                  >
                    01564335999
                  </a>
                </Button>
              </li>
            </Grid>
            <Grid item style={{ display: "flex", alignItems: "center" }}>
              <Typography style={{fontWeight:500}}>WhatsApp:</Typography>
              <li className={classes.listItem}>
                <Button classes={{ root: classes.linkButtonLabel }}>
                  <a
                    className={classes.link}
                    href="https://wa.me/+447831522215"
                    style={{ textTransform: "lowercase" }}
                  >
                    07831522215
                  </a>
                </Button>
              </li>
            </Grid>
          </ul>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default MobileFooter;
