import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    logoIcon:{
        width:"10rem",
        height:"10rem",
        marginLeft: "2rem"
      },
      linkButtonLabel:{
        "&:hover": {
          backgroundColor: "transparent",
        },
      },
      link:{
        color: "black",
        textDecoration: "none",
        textTransform: "Capitalize",
        whiteSpace:"nowrap",
        display: "inline-block",
        '&::after': {
          content: '""',
          display: 'block',
          width: 0,
          height: '2px',
          backgroundColor: 'black',
          transition: 'width .3s',
        },
        '&:hover::after': {
          width: "100%",
        }, 
      },
      socialLabel:{
        display:"flex",
        marginBottom: "0.5rem"
      },
      copyright:{
        textAlign: "center"
      },
      accordionSummary:{
        backgroundColor:"#494949",
        color:"white",
      },
      expandIcon:{
        fill:"white"
      },
      listGroup:{
        paddingInlineStart: 0
      }
}))
