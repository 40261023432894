import React, { useState, useEffect } from "react";

import { Link } from "gatsby";
import { useLocation } from "@reach/router"; // this helps tracking the location
import { initializeAndTrack } from "gatsby-plugin-gdpr-cookies";

import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

const CookieChecker = () => {
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const consentPropertyName = "pch_cookies_consent";

  useEffect(() => {
    if (checkInStorage() === "accept") {
      initializeAndTrack(location);
      return;
    } else if (checkInSession() === "decline") {
      return;
    }
    setOpen(true);
  }, [location]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const acceptAndSave = () => {
    initializeAndTrack(location);
    window.localStorage.setItem(consentPropertyName, "accept");
    setOpen(false);
  };

  const declineAndSave = () => {
    window.sessionStorage.setItem(consentPropertyName, "decline");
    setOpen(false);
  };

  const checkInStorage = () => {
    return window.localStorage.getItem(consentPropertyName);
  };

  const checkInSession = () => {
    return window.sessionStorage.getItem(consentPropertyName);
  };

  return (
    <>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={open}
        // autoHideDuration={6000}
        onClose={handleClose}
        message={
          <p>
            We use cookies to ensure that we give you the best experience on our
            website. If you continue to use this site we will assume that you
            are happy with it.
            <span>
              <Link style={{textDecoration:'none', color:'#E1D836'}} to="/privacy">{" "}Privacy Policy.</Link>
            </span>
          </p>
        }
        action={
          <>
            <Button
              color="secondary"
              size="small"
              onClick={() => acceptAndSave()}
            >
              I Accept
            </Button>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => declineAndSave()}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </>
        }
      />
    </>
  );
};

export default CookieChecker;

// https://www.legalmonster.com/guides/cookie-consent
