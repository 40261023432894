import React, { useState } from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import DehazeIcon from "@material-ui/icons/Dehaze";
import Drawer from "@material-ui/core/Drawer";
import LogoIcon from "../../assests/svg/Logo_2.1_INVERSE_noText.svg";
import { Divider, SvgIcon } from "@material-ui/core";
import { Link } from "gatsby";
import { Button } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { useStyles } from "./Navbar.styling";

const Navbar = ({ position }) => {
  const classes = useStyles();
  const desktop = useMediaQuery("(min-width:769px)");
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const links = [
    {
      name: "About Us",
      linksUrl: "/about",
    },
    {
      name: "careers",
      linksUrl: "/careers",
    },
    {
      name: "Request Callback",
      linksUrl: "/callback",
    },
    {
      name: "Services",
      linksUrl: "/services",
    },
    {
      name: "Contact",
      linksUrl: "/contact",
    },
  ];

  const mobileLinks = [
    {
      name: "Home",
      linksUrl: "/",
    },
    {
      name: "About Us",
      linksUrl: "/about",
    },
    {
      name: "careers",
      linksUrl: "/careers",
    },
    {
      name: "Request Callback",
      linksUrl: "/callback",
    },
    {
      name: "Services",
      linksUrl: "/services",
    },
    {
      name: "Contact",
      linksUrl: "/contact",
    },
  ];
  return (
    <>
      <AppBar className={classes.navbar} position={position} color="primary">
        <Toolbar>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
          >
            <Link to={"/"}>
              <SvgIcon className={classes.logoIcon}>
                <LogoIcon />
              </SvgIcon>
            </Link>
          </IconButton>
          <Typography variant="h5" className={classes.title}>
            PCH Services
          </Typography>
          {desktop && (
            <ul>
              {links.map((link) => (
                <Button
                  key={link.name}
                  classes={{ root: classes.linkButtonLabel }}
                >
                  <li className={classes.linksLabel}>
                    <Link
                      activeStyle={{ color: "#e1d836" }}
                      className={classes.link}
                      to={link.linksUrl}
                    >
                      {link.name}
                    </Link>
                  </li>
                </Button>
              ))}
              {/* <IconButton
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            // onClick={handleMenu}
            color="inherit"
          >
            <AccountCircle />
          </IconButton> */}
            </ul>
          )}
          {!desktop && (
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="menu"
              onClick={() => setIsDrawerOpen(true)}
            >
              <SvgIcon className={classes.menuIcon}>
                <DehazeIcon />
              </SvgIcon>
            </IconButton>
          )}
        </Toolbar>
      </AppBar>
      <Drawer
        classes={{ paper: classes.drawerPaper }}
        anchor="right"
        open={isDrawerOpen}
      >
        <nav className={classes.mobileNav}>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
            onClick={() => setIsDrawerOpen(false)}
          >
            <SvgIcon className={classes.menuCloseIcon}>
              <CloseIcon />
            </SvgIcon>
          </IconButton>
        </nav>
        <ul className={classes.mobileLinkList}>
          {mobileLinks.map((link) => (
            <React.Fragment key={link.name}>
              <Button classes={{ root: classes.linkButtonLabel }}>
                <li className={classes.linksLabel}>
                  <Link className={classes.link} to={link.linksUrl}>
                    {link.name}
                  </Link>
                </li>
              </Button>
              <Divider />
            </React.Fragment>
          ))}
        </ul>
      </Drawer>
    </>
  );
};

export default Navbar;
